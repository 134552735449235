import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../servicios/auth.service';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { Subject } from 'rxjs'; //dataTables
import { ToastrService } from 'ngx-toastr';

declare var jQuery:any;
declare var $:any;

@Component({
  selector: 'app-adm-users',
  templateUrl: './adm-users.component.html',
  styleUrls: ['./adm-users.component.css']
})
export class AdmUsersComponent implements OnInit {
  data;
  allUsers:any = []; //dataTables
  dtOptions: DataTables.Settings = {}; //dataTables
  dtTrigger: Subject<any> = new Subject(); //dataTables

  constructor(public authService: AuthService, 
              private route: ActivatedRoute, 
              public AppComponent: AppComponent,
              public router: Router,
              public toastr: ToastrService
            ) { 
  }

  //dataTables
  ngOnInit():void{
    this.dtOptions = this.AppComponent.optionsDatatables();

    this.authService.loadDataUsers().subscribe( result=> {
      this.allUsers = result;
      this.dtTrigger.next();
      console.log(this.allUsers);
    })

  }

  openEditData(id){
    //console.log(id);
    this.authService.getUserById(id).subscribe( result=> {
      this.data = result;
      console.log(this.data);
    })
    $('#editModal').modal('show');
  }

  openAddData(){
    $('#addModal').modal('show');
  }

  create(){
    var json = $('#frmAdd').serializeJSON();
    json['activo'] = (/true/i).test(json['activo']);
    json['verificado'] = (/true/i).test(json['verificado']);
    //console.log(json);
    this.authService.createUserPost(json).subscribe(
      result => {
        this.data = result;
        if(this.data.msg == 'si'){
          this.toastr.success('Creado correctamente.');
           $('#addModal').modal('hide');
          //window.location.reload();
        }else{
          this.toastr.error('Ocurrió un error crear.');
        }
        //this.router.navigate(['adm-products']);
        //console.log(this.product);
      }
    );
  }

  update(){
    var id = $('#id').val();
    var json = $('#frmUpdate').serializeJSON();
    json['activo'] = (/true/i).test(json['activo']);
    json['verificado'] = (/true/i).test(json['verificado']);
    //console.log(json);
    this.authService.updateUserPut(json,id).subscribe(
      result => {
        this.data = result;
        if(this.data.msg == 'si'){
          this.toastr.success('Actualizado correctamente.');
          this.router.navigate(['/loading']).then(()=>{this.router.navigate(['/adm-users'])});
          $('#editModal').modal('hide');
          //window.location.reload();
        }else{
          $('#editModal').modal('hide');
          this.toastr.error('Ocurrió un error al actualizar.');
        }
        //window.location.reload();
        //console.log(this.product);
      }
    );
  }

  delete(id){
    var c = confirm("Esta seguro de eliminar el registro?");
    if(c==true){
      this.authService.deleteUser(id).subscribe(
        result => {
          $('#'+id).remove();
          this.data = result;
          this.router.navigate(['adm-users']);
          //window.location.reload(); 
          //console.log(this.product);
        }
      );
      this.toastr.success('Eliminado correctamente.');
    }    
  }
    

}
