import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../servicios/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {
  public email: string;
  public password: string;
  public proveedor: string;
  users;
  products;
  frmLogin: FormGroup;

  constructor(public authService: AuthService,
    public router: Router,
    public toastr: ToastrService,
    private fb: FormBuilder
  ) {

    this.frmLogin = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

  }

  ngOnInit() {
  }

  /*
  onSubmitLogin(){
    this.authService.loginEmail(this.email,this.password)
    .then((res)=>{
      this.toastr.success('Usuario logeado correctamente.');
      this.router.navigate(['/private']);
    }).catch((err)=>{
      this.toastr.error(err.message);
      //console.log(err);
      this.router.navigate(['/login']);
    })

  }

  onClickGoogleLogin(){
    //console.log('GOOGLE');
    this.authService.loginGoogle()
    .then((res)=>{
      this.router.navigate(['/private'])
    }).catch(err=>console.log(err.message));
  }

  onClickFacebookLogin(){
    //console.log('GOOGLE');
    this.authService.loginFacebook()
    .then((res)=>{
      this.router.navigate(['/private'])
    }).catch(err=>console.log(err.message));
  }

  onClickTwiterLogin(){
    //console.log('GOOGLE');
    this.authService.loginTwitter()
    .then((res)=>{
      this.router.navigate(['/private'])
    }).catch(err=>console.log(err.message));
  }
  */

  // TIENDA
  submitLogin(): void {
    const email = String($('#email').val());
    const password = $('#password').val();
    const proveedor = $('#proveedor').prop('checked');
    this.authService.loginPost(email, password, proveedor)
      .subscribe(data => {
        this.users = data;

        if (this.users.msg === 'si' && this.users.activo === true && this.users.verificado === true) {
          localStorage.setItem('auth', 'si');
          localStorage.setItem('user', email);
          localStorage.setItem('type_user', 'cliente');
          // this.toastr.success('Ingreso cliente correcto.');
          // Temp: this.router.navigate(['/products/1/9']);
          window.location.href = '/products/1/9';
        } else if (this.users.msg === 'si' && this.users.activoprov === true) {
          localStorage.setItem('auth', 'si');
          localStorage.setItem('user', email);

          if (email === 'admin') {
            alert('Ingreso administrador correcto.');
            localStorage.setItem('type_user', 'admin');
          } else {
            alert('Ingreso usuario correcto.');
            localStorage.setItem('type_user', 'proveedor');
          }

          // Tempt: this.router.navigate(['/products']);
          window.location.href = '/products';
        } else if (this.users.msg === 'si' && this.users.verificado === false) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Verifique que los datos sean correctos.',
            footer: '<a href="">Como puedo solucionarlo?</a>'
          });
        } else if (this.users.msg === 'si' && this.users.activo === false) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Verifique que los datos sean correctos.',
            footer: '<a href="">Como puedo solucionarlo?</a>'
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Verifique que los datos sean correctos.',
            footer: '<a href="">Como puedo solucionarlo?</a>'
          });
        }
      }, err => {
        console.log('Ocurrio un error.');
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Verifique que los datos sean correctos.',
          footer: '<a href="">Como puedo solucionarlo?</a>'
        });
      });
  }

  loginProveedor() {
    if ($('#proveedor').prop('checked')) {
      this.toastr.warning('Login proveedor.');
      $('#txtLabelUser').html('Número de cédula');
    } else {
      $('#txtLabelUser').html('Email');
    }
  }
}
