import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { HomePageComponent } from './componentes/home-page/home-page.component';
import { NavbarComponent } from './componentes/navbar/navbar.component';
import { RegisterPageComponent } from './componentes/register-page/register-page.component';
import { LoginPageComponent } from './componentes/login-page/login-page.component';
import { PrivatePageComponent } from './componentes/private-page/private-page.component';
import { NotFoundPageComponent } from './componentes/not-found-page/not-found-page.component';
import { ProfilePageComponent } from './componentes/profile-page/profile-page.component';
import { CategoriesComponent } from './componentes/categories/categories.component';
import { ProductsComponent } from './componentes/products/products.component';
import { ProductComponent } from './componentes/product/product.component';
import { CartComponent } from './componentes/cart/cart.component';
import { SearchComponent } from './componentes/search/search.component';
import { LoadingComponent } from './componentes/loading/loading.component';
import { InvoiceComponent } from './componentes/invoice/invoice.component';

import { AdmProductsComponent } from './componentes/adm-products/adm-products.component';
import { AdmCategoriesComponent } from './componentes/adm-categories/adm-categories.component';
import { AdmUsersComponent } from './componentes/adm-users/adm-users.component';
import { AdmProviderComponent } from './componentes/adm-provider/adm-provider.component';
import { OrderComponent } from './componentes/order/order.component';
import { AdmOrderProviderComponent } from './componentes/adm-order-provider/adm-order-provider.component';
import { FactProductosComponent } from './componentes/fact-productos/fact-productos.component';
import { ProductsProviderComponent } from './componentes/products-provider/products-provider.component';
import { AdmPublicidadComponent } from './componentes/adm-publicidad/adm-publicidad.component';
import { ConfiguracionComponent } from './componentes/configuracion/configuracion.component';
import { RedirectComponent } from './componentes/redirect/redirect.component';

import {AuthGuard} from './guards/auth.guard';
import { TerminosycondicionesComponent } from './componentes/terminosycondiciones/terminosycondiciones.component';
import { InstructivoComponent } from './componentes/instructivo/instructivo.component';
import { BuscarComponent} from './componentes/buscar/buscar.component';
import { ProfesionalesComponent} from './componentes/profesionales/profesionales.component';
import { BotonpagosComponent } from './componentes/botonpagos/botonpagos.component';
import { AdmOrderAdminComponent } from './componentes/adm-order-admin/adm-order-admin.component';
import { PedidosComponent } from './componentes/pedidos/pedidos.component';
import { HeroComponent } from './componentes/hero/hero.component';

const routes: Routes = [
  {path: '', component: HeroComponent},
  {path: 'home', component: HomePageComponent},
  {path: 'login', component: LoginPageComponent},
  {path: 'register', component: RegisterPageComponent},
  {path: 'private', component: PrivatePageComponent, canActivate: [AuthGuard]},
  {path: 'profile', component: ProfilePageComponent},
  {path: 'categories', component: CategoriesComponent},
  {path: 'products', component: ProductsComponent},
  {path: 'products/:id', component: ProductsComponent},
  {path: 'products/:numpage/:numprod', component: ProductsComponent},
  {path: 'products/:idCategorie/:numpage/:numprod', component: ProductsComponent},
  {path: 'products-provider/:proveedor', component: ProductsProviderComponent},
  {path: 'product/:id', component: ProductComponent},
  {path: 'search/:product', component: SearchComponent},
  {path: 'cart', component: CartComponent},
  {path: 'cart/:user', component: CartComponent},
  {path: 'adm-products', component: AdmProductsComponent},
  {path: 'adm-categories', component: AdmCategoriesComponent},
  {path: 'adm-users', component: AdmUsersComponent},
  {path: 'adm-provider', component: AdmProviderComponent},
  {path: 'adm-order-provider', component: AdmOrderProviderComponent},
  {path: 'order', component: OrderComponent},
  {path: 'loading', component: LoadingComponent},
  {path: 'invoice', component: InvoiceComponent},
  {path: 'invoice-products', component: FactProductosComponent},
  {path: 'publicidad', component: AdmPublicidadComponent},
  {path: 'configuracion', component: ConfiguracionComponent},
  {path: 'redirect/:ordenid', component: RedirectComponent},
  {path: 'terminosycondiciones', component: TerminosycondicionesComponent},
  {path: 'instructivo', component: InstructivoComponent},
  {path: 'buscar', component: BuscarComponent},
  {path: 'profesionales', component: ProfesionalesComponent},
  {path: 'botonpagos/:ordenid', component: BotonpagosComponent},
  {path: 'adm-order-admin', component: AdmOrderAdminComponent},
  {path: 'pedidos', component: PedidosComponent},
  {path: '**', component: NotFoundPageComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
