import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../servicios/auth.service';
import { AppComponent } from '../../app.component';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-products-provider',
  templateUrl: './products-provider.component.html',
  styleUrls: ['./products-provider.component.css']
})
export class ProductsProviderComponent implements OnInit {
  allData;
  products;
  limit = 12;
  pag = 1;
  portada;

  constructor(
    public authService: AuthService,
    private route: ActivatedRoute,
    public appComponent: AppComponent) {
      this.authService
      .proveedorcedula
      (this.route.snapshot.paramMap.get('proveedor'))
      .subscribe(
        result => {
          this.allData = result
          this.portada = this.allData.imgportada
          console.log(this.portada)
          var por = document.getElementById('portada');
              por.style.backgroundImage =  "url("+this.portada+")";
              por.style.backgroundSize = "cover";
              por.style.backgroundPosition = "center";
        }
      )
    this.authService
    .getProductsProvider
    (this.route.snapshot.paramMap.get('proveedor'), this.pag, this.limit)
    .subscribe(
      data => {
        this.products = data[2];
        this.products = this.products.products;
        console.log(this.products);
      },
    );

    $(window).scroll(function() {
      if ($(this).scrollTop() > 100) {
           $('.scrollup').fadeIn();
      } else {
           $('.scrollup').fadeOut();
      }
    });

  }

  ngOnInit() {}

  scrollTop() {
    $('html, body').animate({ scrollTop: 0 }, 600);
    return false;
  }

  doInfinite(infiniteScroll) {
    console.log('Begin async operation');
    this.pag += 1;

    setTimeout(() => {
      this.authService
      .getProductsProvider(this.route.snapshot.paramMap.get('proveedor'), this.pag, this.limit)
      .subscribe(
        data => {
          this.products = this.products.concat(data[2].products);
        },
        error => {console.log(error); }
      );
      console.log('Async operation has ended');
    }, 600);

  }

}
