import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../servicios/auth.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-adm-publicidad',
  templateUrl: './adm-publicidad.component.html',
  styleUrls: ['./adm-publicidad.component.css']
})
export class AdmPublicidadComponent implements OnInit {
  settings;
  img;
  loader;
  msn;
  constructor(
    private srv: AuthService,
    private flashMessagesService: FlashMessagesService
  ) { }

  ngOnInit() {
    this.srv.getSettings().subscribe(data => {
      this.settings = data;
    });
  }

  cargaArchivo(ev) {
    const img: any = ev.target;
    this.img = img;
    if (img.files.length > 0) {
      this.loader = true;
      const form = new FormData();
      form.append('image', img.files[0]);
      this.msn = img.files[0]['name'] + ' Peso:' + Math.round(img.files[0]['size'] / 1024) + 'Kb';
      if (img.files[0].size > 307200) {
        this.flashMessagesService.show('El archivo supera el limite de 3MB.', { cssClass: 'alert-danger', timeout: 3000 });
        return;
      }

      this.srv.uploadImage(form).subscribe(
        resp => {
          this.loader = false;
          this.flashMessagesService.show('La imagen se cargó correctamente.', { cssClass: 'alert-success', timeout: 3000 });
          let imagename = '';
          imagename = environment.tiendaConfig.BASE_API_URL + 'imageproducts/' + img.files[0]['name'];
          $('#imageServer').html('<img class="img-fluid" src="' + imagename + '" alt="Imagen" height="60">');
          let arrimages = [];
          arrimages = this.settings.imagspublicidad;
          arrimages.push(imagename);
          const datos = {};
          datos['imagspublicidad'] = arrimages;
          this.srv.putSettings(datos).subscribe(data1 => {
            console.log(data1);
          });
        },
        error => {
          this.loader = false;
          this.flashMessagesService.show('Ocurrió un error al cargar la imagen.', { cssClass: 'alert-danger', timeout: 3000 });
        }
      );

    }
  }

  eliminar(i) {
    let arrimages = [];
    arrimages = this.settings.imagspublicidad;
    arrimages.splice(i, 1);
    const datos = {};
    datos['imagspublicidad'] = arrimages;
    this.srv.putSettings(datos).subscribe(data1 => {
      console.log(data1);
    });
  }

}
