import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../servicios/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {
  constructor(private srv: AuthService, private route: ActivatedRoute, public router: Router,) {}
  post;

  ngOnInit() {
    this.route.params.subscribe(
      data => {
        console.log(data);
        // window.location.href = 'https://allsys.tk:8000/postpay/' + data['ordenid'];
        window.location.href = 'https://tienda.tangotiendas.com:8008/postpay/' + data['ordenid'];
        // window.location.href = 'https://tangotiendas.com/botonpagos/';
        // this.router.navigate(['/botonpagos']).then(() => { this.router.navigate(['/botonpagos']); });

        
        // window.location.href = 'http://localhost:5000/postpay/' + data['ordenid'];
      }
    );

    // window.location.href = 'https://tangotiendas.com/postpay/2100';
    // const datos = {};
    // this.srv.postpay(datos).subscribe((data) => {
    //   console.log(data);
    //   this.post = data;
    // });
  }
}
