import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../servicios/auth.service';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-buscar',
  templateUrl: './buscar.component.html',
  styleUrls: ['./buscar.component.scss']
})
export class BuscarComponent implements OnInit {
  allData: any = []; // dataTables
  public isLogin: boolean;
  public nombreUsuario: string;
  public emailUsuario: string;
  public fotoUsuario: string;

  public isLoginPage: boolean;
  public type_user: any;

  public categories: any;

  public product;
  panelOpenState = false;
  public value;


  constructor(
    public authService: AuthService,
    // public AppComponent: AppComponent,
    private router: Router
  ) { }

  ngOnInit() {
    this.authService.loadDataProviders().subscribe(result => {
      this.allData = result;
    });

    // this.AppComponent.numberItemsFromCart();
    this.getAllCategories();

    /*
    this.authService.getAut().subscribe(auth => {
      if(auth){
        this.isLogin=true;
        this.nombreUsuario=auth.displayName;
        this.emailUsuario=auth.email;
        this.fotoUsuario=auth.photoURL;
      }else{
        this.isLogin=false;
      }
    });
    */

    if (localStorage.getItem('auth') === 'si') {
      this.isLoginPage = true;
    } else {
      this.isLoginPage = false;
    }
    this.type_user = localStorage.getItem('type_user');

    if (localStorage.getItem('user')) {
      this.emailUsuario = localStorage.getItem('user');
    } else {
      this.emailUsuario = 'NO';
    }

  }

  /*
  onClickLogout(){
    this.authService.logout();
  }
  */

  onLogoutApp() {
    this.authService.logoutApp();
    window.location.href = '/';
  }

  getAllCategories() {
    this.authService.loadDataCategories().subscribe(
      data => { this.categories = data; },
      err => console.error(err)/*,
      () => console.log(this.categories)*/
    );
  }

  search() {
    if (this.router.url.indexOf('search') > 0) {
      this.product = $('#search').val();
      this.router.navigate(['/loading']).then(() => { this.router.navigate(['/search/' + this.product]); });
    } else {
      this.product = $('#search').val();
      this.router.navigate(['/search/' + this.product]);
    }
  }

}
