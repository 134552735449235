import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../servicios/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from '../../app.component';
import Swal from 'sweetalert2';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.css']
})

export class RegisterPageComponent implements OnInit {
  data: any = [];

  public email: string;
  public name: string;
  public password: string;
  public users: any;
  public frmRegister: FormGroup;
  public type: string;
  public phone: String;
  public address: String;

  constructor(public authService: AuthService,
    public router: Router,
    public appComponent: AppComponent,
    private fb: FormBuilder
  ) {

    this.frmRegister = this.fb.group({
      email: [null, Validators.required],
      name: [null, Validators.required],
      identificacion: [null, Validators.required],
      password: [null, Validators.required],
      phone: [null, Validators.required],
      address: [null, Validators.required],
      type: ['P', Validators.required]
    });

  }

  ngOnInit() {
    this.setRegisterTypeValidators();
  }

  /*
  onSubmitAddUser(){
    this.authService.registerUser(this.email, this.password)
    .then((res)=>{
      this.toastr.success('Usuario creado correctamente.');
      this.router.navigate(['/private']);
    }).catch((err)=>{
      this.toastr.error(err.message);
      console.log(err);
    })
  }
  */
  setRegisterTypeValidators () {
    const phoneControl = this.frmRegister.get('phone');
    const addressControl = this.frmRegister.get('address');

    this.frmRegister.controls.type.valueChanges.subscribe(type => {
        if (type === 'U') {
          phoneControl.setValidators(null);
          addressControl.setValidators(null);
        } else {
          phoneControl.setValidators([Validators.required]);
          addressControl.setValidators([Validators.required]);
        }

        phoneControl.updateValueAndValidity();
        addressControl.updateValueAndValidity();
      });
  }

  submitRegister() {
    const email = this.frmRegister.get('email').value;
    const name = this.frmRegister.get('name').value;
    const password = this.frmRegister.get('password').value;
    const cedula = this.frmRegister.get('identificacion').value;
    const phone = this.frmRegister.get('phone').value;
    const address = this.frmRegister.get('address').value;
    const datos = {};
    datos['usuario'] = email;
    datos['nombres'] = name;
    datos['cedula'] = cedula;
    datos['pwd'] = password;
    datos['tipo'] = this.frmRegister.get('type').value;
    if (this.frmRegister.get('type').value === 'P') {
      datos['telefonos'] = phone;
      datos['direccion'] = address;
    }
    this.authService.registerPost(datos)
      .subscribe(data => {
        this.users = data;
        if (this.users.msg === 'si') {
          if (this.frmRegister.get('type').value === 'P') {
            Swal.fire({
              icon: 'success',
              title: 'Excelente',
              text: 'Por favor ve revisa tu correo electronico, y confirma tu registro.',
              confirmButtonText: 'Iniciar sesión',
              footer: '<a href="">Tienes algun problema?</a>'
            }).then((result) => {
              if (result.isConfirmed) {
                this.router.navigate(['/login']);
              }
          });
          } else {
            alert('Tu registro está completo, dirígete a tu email para activar tu cuenta.');
          };
          
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Error en el registro, Ya se encuentra registrado o verifique que los datos sean correctos.',
            footer: '<a href="">Como puedo solucionarlo?</a>'
          });
        }
      }, err => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Por favor revisa tus datos e intenta nuevamente.',
          footer: '<a href="">Como puedo solucionarlo?</a>'
        });
      });
  }

}
