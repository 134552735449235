// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  tiendaConfig: {
    BASE_APP_URL: 'https://localhost:4200/',
    // BASE_API_URL: 'https://tangotienda.herokuapp.com/'
    // BASE_API_URL: 'https://allsys.tk:8000/'
    // BASE_API_URL: 'http://localhost:5000/'
    BASE_API_URL: 'https://tienda.tangotiendas.com:8008/'
  },
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyD_KTqd5tS0d1OwB3gwktMIqrgOdh-lFbo',
    authDomain: 'tienda-516a4.firebaseapp.com',
    databaseURL: 'https://tienda-516a4.firebaseio.com',
    projectId: 'tienda-516a4',
    storageBucket: '',
    messagingSenderId: '1093325081764'
  }
};
