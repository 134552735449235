import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../servicios/auth.service';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { Subject } from 'rxjs'; // dataTables
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-adm-categories',
  templateUrl: './adm-categories.component.html',
  styleUrls: ['./adm-categories.component.css']
})
export class AdmCategoriesComponent implements OnInit {

  products;
  allCategories: any = []; // dataTables
  dtOptions: DataTables.Settings = {}; // dataTables
  dtTrigger: Subject<any> = new Subject(); // dataTables

  img;
  loader = false;
  trueimg;
  myimg;
  imagename = 'prueba.png';
  msn;
  image;

  data: any = [];
  constructor(public authService: AuthService,
    private route: ActivatedRoute,
    public AppComponent: AppComponent,
    public router: Router,
    public toastr: ToastrService
  ) {}

  // dataTables
  ngOnInit(): void {
    this.imagecategoria();
    this.dtOptions = this.AppComponent.optionsDatatables();
    this.authService.loadDataCategories().subscribe( result => {
      this.allCategories = result;
      this.dtTrigger.next();
      console.log(this.allCategories);
    });
  }

  openEditData(id) {
    this.authService.getCategorieById(id).subscribe( result => {
      this.data = result;
      console.log(this.data);
    });
    $('#editModal').modal('show');
  }

  openAddData() {
    $('#addModal').modal('show');
  }

  create() {
    let json = {};
    json = this.AppComponent.arrayToJSON('frmAdd');
    
    json['image'] =
      environment.tiendaConfig.BASE_API_URL + 'imageproducts/' + this.imagename;
      console.log(json);
    this.authService.createCategoriePost(json).subscribe(
      result => {
        this.data = result;
        if (this.data.msg === 'si') {
          alert('Creado correctamente.');
          $('#addModal').modal('hide');
          this.router.navigate(['/loading']).then(() => {this.router.navigate(['/adm-categories']); });
          // window.location.reload();
        } else {
          this.toastr.error('Ocurrió un error crear.');
        }
        // this.router.navigate(['adm-categories']);
        // console.log(this.data.msg);
      }
    );
  }

  update() {
    let json = {};
    const id = $('#id').val();
    json = this.AppComponent.arrayToJSON('frmUpdate');
    json['image'] =
      environment.tiendaConfig.BASE_API_URL + 'imageproducts/' + this.imagename;
    this.authService.updateCategoriePut(json, id).subscribe(
      result => {
        this.data = result;
        if (this.data.msg === 'si') {
          this.toastr.success('Actualizado correctamente.');
          this.router.navigate(['/loading']).then(() => {this.router.navigate(['/adm-categories']); });
          $('#editModal').modal('hide');
          // window.location.reload();
        } else {
          $('#editModal').modal('hide');
          this.toastr.error('Ocurrió un error al actualizar.');
        }
        // $('#editModal').modal('hide');
        // window.location.reload();
        // console.log(this.product);
      }
    );
  }

  delete(id) {
    const c = confirm('Esta seguro de eliminar el registro?');
    if (c === true) {
      this.authService.deleteCategorie(id).subscribe(
        result => {
          $('#' + id).remove();
          this.data = result;
          this.router.navigate(['/loading']).then(() => {this.router.navigate(['/adm-categories']); });
          // window.location.reload();
        }
      );
      this.toastr.success('Eliminado correctamente.');
    }
  }

  cargaArchivo(ev) {
    const img: any = ev.target;
    this.img = img;
    const uniqueNumber = new Date().getTime().toString();
    const extension = img.files[0]['name'].split('.')[1];
    this.imagename = uniqueNumber.toString() + '.' + extension ;
    if (img.files.length > 0) {
      this.loader = true;
      const form = new FormData();
      form.append('image', img.files[0]);
      form.append('nombreimagen', this.imagename);
      this.msn =
        img.files[0]['name'] +
        ' Peso:' +
        Math.round(img.files[0]['size'] / 1024) +
        'Kb';
      if (img.files[0].size > 307200) {
        alert('El archivo supera el limite de 3MB.');
        return;
      }

      this.authService.uploadImage(form).subscribe(
        (resp) => {
          this.loader = false;
          alert('La imagen se cargó correctamente.');
          this.allCategories.image = environment.tiendaConfig.BASE_API_URL +'imageproducts/'+this.imagename;
          console.log(this.allCategories.image)
          $('#imageServerLogo').html(
            '<img class="img-fluid" src="' +
            this.allCategories.image +
              '" alt="Imagen" style="width: 25%;">'
          );
        },
        (error) => {
          this.loader = false;
          alert('Ocurrió un error al cargar la imagen.');
        }
      );
    }
  }
  imagecategoria(){
    const image = environment.tiendaConfig.BASE_API_URL +'imageproducts/'+this.imagename;
    

  
  }


}
