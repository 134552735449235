import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../servicios/auth.service';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { Subject } from 'rxjs'; // dataTables
import { ToastrService } from 'ngx-toastr';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-adm-provider',
  templateUrl: './adm-provider.component.html',
  styleUrls: ['./adm-provider.component.css']
})
export class AdmProviderComponent implements OnInit {

  provider;
  allData: any = []; // dataTables
  dtOptions: DataTables.Settings = {}; // dataTables
  dtTrigger: Subject<any> = new Subject(); // dataTables

  data: any = [];

  constructor(
    public authService: AuthService,
    private route: ActivatedRoute,
    public appComponent: AppComponent,
    public router: Router,
    public toastr: ToastrService
  ) { }

  // dataTables
  ngOnInit(): void {
    this.dtOptions = this.appComponent.optionsDatatables();

    this.authService.loadDataProviders().subscribe( result => {
      this.allData = result;
      this.dtTrigger.next();
      console.log(this.allData)
    });

  }

  openEditData(id) {
    this.authService.getProviderById(id).subscribe( result => {
      this.provider = result;
    });
    $('#editModal').modal('show');
  }

  openAddData() {
    $('#addModal').modal('show');
  }

  create() {
    let json = {};
    json = this.appComponent.arrayToJSON('frmAdd');
    json['tienda'] = (/true/i).test(json['tienda']);
    json['activo'] = (/true/i).test(json['activo']);
    json['billing'] = (/true/i).test(json['billing']);
    this.authService.createProviderPost(json).subscribe(
      result => {
        this.data = result;
        if (this.data.msg === 'si') {
          alert('Creado correctamente.');
          this.router.navigate(['/loading']).then(() => {this.router.navigate(['/adm-provider']); });
          $('#addModal').modal('hide');
        } else {
          alert('Ocurrió un error crear.');
        }
      }
    );
    console.log(this.authService.createProviderPost(json).subscribe);
  }

  update() {
    let json = {};
    const id = $('#id').val();
    json = this.appComponent.arrayToJSON('frmUpdate');
    json['tienda'] = (/true/i).test(json['tienda']);
    json['activo'] = (/true/i).test(json['activo']);
    json['billing'] = (/true/i).test(json['billing']);


    this.authService.updateProviderPut(json, id).subscribe(
      result => {
        this.data = result;
        if (this.data.msg === 'si') {
          this.toastr.success('Actualizado correctamente.');
          this.router.navigate(['/loading']).then(() => {this.router.navigate(['/adm-provider']); });
        } else {
          this.toastr.error('Ocurrió un error al actualizar.');
        }
        $('#editModal').modal('hide');
      }
    );

  }

  delete(id) {
    const c = confirm('Esta seguro de eliminar el registro?');
    if (c === true) {
      this.authService.deleteProvider(id).subscribe(
        result => {
          $('#' + id).remove();
          this.data = result;
          this.router.navigate(['adm-provider']);
        }
      );
      confirm('Eliminado correctamente.');
    }
  }
}
