import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-botonpagos',
  templateUrl: './botonpagos.component.html',
  styleUrls: ['./botonpagos.component.scss']
})
export class BotonpagosComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(
      data => {
        console.log('datoooooooos' + data['ordenid']);})
  }

}
