import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../servicios/auth.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { environment } from '../../../environments/environment';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.css']
})
export class ConfiguracionComponent implements OnInit {
  datos = {
    nombres: '',
    nombre_comercio:'',
    descripcion_comercio:'',
    tag_comercio:'',
    cedula:'',
    direccion: '',
    telefonos: '',
    pais: '',
    ciudad: '',
    calle1: '',
    calle2: '',
    numcasa: '',
    correo: '',
    ruc: '',
    razonsocial: '',
    nombrecomercial: '',
    secuencia: '',
    codigoestablecimiento: '',
    codigopuntoemision: '',
    dirmatriz: '',
    obligadoContabilidad: '',
    pwdfirma: '',
    imglogo:'',
    imgportada:'',
    imgperfil:''
  };
  img;
  loader = false;
  trueimg;
  myimg;

  imagename = 'prueba.png';
  msn;
  usuario;
  constructor(
    public authService: AuthService,
    private flashMessagesService: FlashMessagesService
  ) { }

  ngOnInit() {
    this.usuario = localStorage.getItem('user');
    this.authService.proveedorcedula(this.usuario).subscribe(data => {
      console.log(this.datos);
    this.datos.nombres = data['nombres'];
    this.datos.nombre_comercio = data['nombre_comercio'];
    this.datos.descripcion_comercio = data['descripcion_comercio'];
    this.datos.tag_comercio = data['tag_comercio'];
    this.datos.cedula = data['cedula'];
    this.datos.direccion = data['direccion'];
    this.datos.telefonos = data['telefonos'];
    this.datos.pais = data['pais'];
    this.datos.ciudad = data['ciudad'];
    this.datos.calle1 = data['calle1'];
    this.datos.calle2 = data['calle2'];
    this.datos.numcasa = data['numcasa'];
    this.datos.correo = data['correo'];
    this.datos.ruc = data['ruc'];
    this.datos.razonsocial = data['razonsocial'];
    this.datos.nombrecomercial = data['nombrecomercial'];
    this.datos.secuencia = data['secuencia'];
    this.datos.codigoestablecimiento = data['codigoestablecimiento'];
    this.datos.codigopuntoemision = data['codigopuntoemision'];
    this.datos.dirmatriz = data['dirmatriz'];
    this.datos.obligadoContabilidad = data['obligadoContabilidad'];
    this.datos.pwdfirma = data['pwdfirma'];
    this.datos.imglogo = data['imglogo'];
    this.datos.imgportada = data['imgportada'];
    this.datos.imgperfil = data['imgperfil'];
    });
  }

  guardar() {
      this.authService.updateProviderPut(this.datos, this.usuario).subscribe(data => {
      console.log(data);
      alert('Información almacenada');
    });
  }

  cargaArchivo(ev) {
    const img: any = ev.target;
    this.img = img;
    const uniqueNumber = new Date().getTime().toString();
    const extension = img.files[0]['name'].split('.')[1];
    this.imagename = uniqueNumber.toString() + '.' + extension ;
    if (img.files.length > 0) {
      this.loader = true;
      const form = new FormData();
      form.append('image', img.files[0]);
      form.append('nombreimagen', this.imagename);
      this.msn =
        img.files[0]['name'] +
        ' Peso:' +
        Math.round(img.files[0]['size'] / 1024) +
        'Kb';
      if (img.files[0].size > 307200) {
        alert('El archivo supera el limite de 3MB.');
        return;
      }

      this.authService.uploadImage(form).subscribe(
        (resp) => {
          this.loader = false;
          alert('La imagen se cargó correctamente.');
          this.datos.imglogo = environment.tiendaConfig.BASE_API_URL +'imageproducts/'+this.imagename;
          $('#imageServerLogo').html(
            '<img class="img-fluid" src="' +
            this.datos.imglogo +
              '" alt="Imagen" style="width: 25%;">'
          );
        },
        (error) => {
          this.loader = false;
          alert('Ocurrió un error al cargar la imagen.');
        }
      );
    }
  }

  portada(ev) {
    const img: any = ev.target;
    this.img = img;
    const uniqueNumber = new Date().getTime().toString();
    const extension = img.files[0]['name'].split('.')[1];
    this.imagename = uniqueNumber.toString() + '.' + extension ;
    if (img.files.length > 0) {
      this.loader = true;
      const form = new FormData();
      form.append('image', img.files[0]);
      form.append('nombreimagen', this.imagename);
      this.msn =
        img.files[0]['name'] +
        ' Peso:' +
        Math.round(img.files[0]['size'] / 1024) +
        'Kb';
      if (img.files[0].size > 307200) {
        alert('El archivo supera el limite de 3MB.');
        return;
      }

      this.authService.uploadImage(form).subscribe(
        (resp) => {
          this.loader = false;
          alert('La imagen se cargó correctamente.');
          this.datos.imgportada = environment.tiendaConfig.BASE_API_URL +'imageproducts/'+this.imagename;
          $('#imageServerPortada').html(
            '<img class="img-fluid" src="' +
            this.datos.imgportada +
              '" alt="Imagen" style="width: 25%;">'
          );
        },
        (error) => {
          this.loader = false;
          alert('Ocurrió un error al cargar la imagen.');
        }
      );
    }
  }
  
  perfil(ev) {
    const img: any = ev.target;
    this.img = img;
    const uniqueNumber = new Date().getTime().toString();
    const extension = img.files[0]['name'].split('.')[1];
    this.imagename = uniqueNumber.toString() + '.' + extension ;
    if (img.files.length > 0) {
      this.loader = true;
      const form = new FormData();
      form.append('image', img.files[0]);
      form.append('nombreimagen', this.imagename);
      this.msn =
        img.files[0]['name'] +
        ' Peso:' +
        Math.round(img.files[0]['size'] / 1024) +
        'Kb';
      if (img.files[0].size > 307200) {
        alert('El archivo supera el limite de 3MB.');
        return;
      }

      this.authService.uploadImage(form).subscribe(
        (resp) => {
          this.loader = false;
          alert('La imagen se cargó correctamente.');
          this.datos.imgperfil = environment.tiendaConfig.BASE_API_URL +'imageproducts/'+this.imagename;
          $('#imageServerPerfil').html(
            '<img class="img-fluid" src="' +
            this.datos.imgperfil +
              '" alt="Imagen" style="width: 25%;">'
          );
        },
        (error) => {
          this.loader = false;
          alert('Ocurrió un error al cargar la imagen.');
        }
      );
    }
  }
  
}
