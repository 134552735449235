import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../servicios/auth.service';
import { AppComponent } from '../../app.component';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  products;
  idProduct: any;
  limit = 12;
  pag = 1;

  constructor(
    public authService: AuthService,
    private route: ActivatedRoute,
    public appComponent: AppComponent) {
    if (this.route.snapshot.paramMap.get('id') === null) {
      this.authService.getProductsPagination(this.pag, this.limit).subscribe(
        data => {
          this.products = data[2];
          this.products = this.products.products;
          console.log(this.products);
        },
      );
    } else {
      this.authService.getProductsByCategoriePagination(this.route.snapshot.paramMap.get('id'), this.pag, this.limit).subscribe(
        data => {
          this.products = data[2];
          this.products = this.products.products;
          console.error(this.products);
        },
        err => console.error(err),
        () => console.log(this.products.products)
      );
    }

    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.scrollup').fadeIn();
      } else {
        $('.scrollup').fadeOut();
      }
    });

  }

  ngOnInit() {

    if (this.route.snapshot.paramMap.get('id') === null) {
      $('#nombre_categoria').html('Todos los productos');
    } else {
      $('#nombre_categoria').html(this.route.snapshot.paramMap.get('id'));
    }

    // this.getAllProducts();
  }

  scrollTop() {
    $('html, body').animate({ scrollTop: 0 }, 600);
    return false;
  }

  doInfinite(infiniteScroll) {
    console.log('Begin async operation');
    this.pag += 1;

    if (this.route.snapshot.paramMap.get('id') === null) {
      setTimeout(() => {
        this.authService.getProductsPagination(this.pag, this.limit).subscribe(
          data => {
            this.products = this.products.concat(data[2].products);
          },
          error => { console.log(error); }
        );
        console.log('Async operation has ended');
      }, 600);
    } else {
      setTimeout(() => {
        this.authService.getProductsByCategoriePagination(this.route.snapshot.paramMap.get('id'), this.pag, this.limit).subscribe(
          data => {
            this.products = this.products.concat(data[2].products);
          },
          error => { console.log(error); }
        );
        console.log('Async operation has ended');
      }, 600);
    }

  }

  getAllProducts() {
    if (this.route.snapshot.paramMap.get('id') === null) {
      this.authService.getAllProducts().subscribe(
        data => { this.products = data; },
        err => console.error(err),
        () => console.log(this.products)
      );
    } else {
      this.authService.getProductsByCategorie(this.route.snapshot.paramMap.get('id')).subscribe(
        data => { this.products = data; },
        err => console.error(err),
        () => console.log(this.products)
      );
    }

  }

  getParams() {
    return this.route.params.subscribe(
      data => { this.idProduct = data['id']; },
      err => console.error(err),
      () => console.log(this.idProduct)
    );

  }

}
