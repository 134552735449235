import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../servicios/auth.service';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as math from 'mathjs';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
  dateNow = new Date();
  dateParsed = '';
  // productoslista: Array<any> = [{ item: '' }];
  productoslista: Array<any> = [];
  newItem: any = {};
  dataRuc: any = '';
  empresa = { idEmpresa: '01' };
  impuestos = '';
  totalconimpuestos = 0;
  ruc = '1792572878001';
  id;
  facturas = {
    razonSocialComprador: '',
    fechaEmision: '',
    identificacionComprador: '',
    email: '',
    totalSinImpuestos: '',
    total: ''
  };
  datap;
  keyword = 'descripcion';

  //frmRevisaOrden: FormGroup;

  constructor(
    public authService: AuthService,
    private route: ActivatedRoute,
    public AppComponent: AppComponent,
    public router: Router,
    public toastr: ToastrService
  ) {
    this.dateParsed = formatDate(this.dateNow, 'dd-MM-yyyy', 'en-US', '+0530');
  }

  ngOnInit() { }

  addItem() {
    let item = { cantidad: 0, valor: 0, producto: '' };
    this.productoslista.push(item);
    console.log(this.productoslista);
    this.newItem = {};
  }

  removeItem(index) {
    this.productoslista.splice(index, 1);
  }

  calculate() {
    let subTotal = 0;
    let iva = 0;
    let cont = 0;
    let total = 0;

    for (let i of this.productoslista) {
      subTotal = subTotal + i.cantidad * i.valorunitario;
      this.productoslista[cont].vTotal = (i.cantidad * i.valorunitario).toFixed(
        2
      );
      cont += 1;
    }
    $('#subTotal').val(subTotal.toFixed(2));
    iva = subTotal * 0.12;
    $('#iva').val(iva.toFixed(2));
    total = subTotal + iva;
    $('#total').val(total.toFixed(2));
    if (total >= 0) {
      return true;
    } else {
      return false;
    }
  }

  invoice() {
    if (this.calculate()) {
      this.toastr.success('Factura generada correctamente.');
    } else {
      this.toastr.error(
        'Ocurrió un error, verifique el correcto ingreso de valores.'
      );
    }
  }

  limpiar() {
    // this.productoslista = [{ item: '' }];
    this.productoslista = [];
    $('#ruc').val('');
    $('#direccion').val('');
    $('#correo').val('');
    $('#telefono').val('');
    $('#cliente').val('');
    this.facturas.total = '0';
    this.facturas.totalSinImpuestos = '0';
    this.impuestos = '0';

  }

  getDataRuc() {
    let identificador = $('#ruc').val();
    this.authService.getDataRuc(identificador).subscribe(
      data => {
        this.dataRuc = data;
        $('#cliente').val(this.dataRuc[0].nombres);
        $('#correo').val(this.dataRuc[0].usuario);
      },
      err => console.error(err),
      () => console.log(this.dataRuc)
    );
  }

  getItems(i) {
    const producto = this.productoslista[i]['producto'];
    this.authService.getProductosFact(producto).subscribe(data => {
      console.log(data);

      const total = math.round(
        data[0]['valorunitario'] * this.productoslista[i]['cantidad'],
        2
      );
      this.productoslista[i]['codigoprincipal'] = data[0]['codigoprincipal'];
      this.productoslista[i]['codigosecundario'] = data[0]['codigosecundario'];
      this.productoslista[i]['descripcion'] = data[0]['descripcion'];
      this.productoslista[i]['impuestos'] = data[0]['impuestos'];
      this.productoslista[i]['cantidad'] = data[0]['cantidad'];
      this.productoslista[i]['valorunitario'] = data[0]['valorunitario'];
      this.productoslista[i]['total'] = total;
      this.productoslista[i]['vTotal'] = total.toString();
      this.calcsubTotal();
      this.calcTotalT();
    });
  }

  selectEvent(e, i) {
    console.log(e);
    const total = math.round(
      e['valorunitario'] * this.productoslista[i]['cantidad'],
      2
    );
    this.productoslista[i]['codigoprincipal'] = e['codigoprincipal'];
    this.productoslista[i]['codigosecundario'] = e['codigosecundario'];
    this.productoslista[i]['descripcion'] = e['descripcion'];
    this.productoslista[i]['impuestos'] = e['impuestos'];
    this.productoslista[i]['cantidad'] = e['cantidad'];
    this.productoslista[i]['valorunitario'] = e['valorunitario'];
    this.productoslista[i]['total'] = total;
    this.productoslista[i]['vTotal'] = total.toString();
    this.calcsubTotal();
    this.calcTotalT();
  }

  onFocused(i) {
    console.log(i);
  }

  onChangeSearch(e, i) {
    if (e.length < 4) {
      return;
    }
    console.log(this.productoslista);
    const producto = e;
    this.authService.getProductosFact(producto).subscribe(data => {
      console.log(this.productoslista[i]['cantidad']);
      // console.log(data);
      this.datap = data;
      const total = math.round(
        data[0]['valorunitario'] * this.productoslista[i]['cantidad'],
        2
      );
      this.productoslista[i]['codigoprincipal'] = data[0]['codigoprincipal'];
      this.productoslista[i]['codigosecundario'] = data[0]['codigosecundario'];
      this.productoslista[i]['descripcion'] = data[0]['descripcion'];
      this.productoslista[i]['impuestos'] = data[0]['impuestos'];
      this.productoslista[i]['cantidad'] = data[0]['cantidad'];
      this.productoslista[i]['valorunitario'] = data[0]['valorunitario'];
      this.productoslista[i]['total'] = total;
      this.productoslista[i]['vTotal'] = total.toString();
      this.calcsubTotal();
      this.calcTotalT();
    });
  }

  calcsubTotal() {
    let sumat = 0;
    for (const vt of this.productoslista) {
      sumat += parseFloat(vt.total);
    }
    this.facturas.totalSinImpuestos = sumat.toString();
    sumat = math.round(sumat, 2);
    this.facturas.totalSinImpuestos = sumat.toString();
  }

  calcTotalT() {
    // ******************************
    let totalSinImpuestos = 0;
    let totalImpuestos = 0;
    let impuestostotales = [];
    for (var c in this.productoslista) {
      const prod = this.productoslista[c];
      totalSinImpuestos += parseFloat(prod.total);
      let totalitemimpuestos = 0;
      let itemimpuesto = 0;
      let impuestosProducto = [];
      let iceValorTotal = 0;
      let iceBaseimponibleToral = 0;
      // esto es para sacar totales del ice
      for (let h in prod.impuestos) {
        let iceitemimpuesto = 0;
        let baseImponibleProd = parseFloat(prod.total);
        let ice = {};
        iceitemimpuesto =
          (parseFloat(prod.impuestos[h].tarifa) / 100) * baseImponibleProd;
        iceitemimpuesto = Number(math.round(iceitemimpuesto, 2));
        ice['codigo'] = prod.impuestos[h].codigo;
        ice['tarifa'] = prod.impuestos[h].tarifa;
        ice['codigoPorcentaje'] = prod.impuestos[h].codigoPorcentaje;
        ice['valor'] = math.round(iceitemimpuesto, 2);
        ice['baseImponible'] = parseFloat(prod.total);
        if (ice['codigo'] == 3) {
          iceValorTotal += ice['valor'];
          iceBaseimponibleToral += ice['baseImponible'];
        }
      }
      for (let i in prod.impuestos) {
        let codigo_tarifa = {};
        codigo_tarifa['codigo'] = prod.impuestos[i].codigo;
        codigo_tarifa['tarifa'] = prod.impuestos[i].tarifa;
        codigo_tarifa['codigoPorcentaje'] = prod.impuestos[i].codigoPorcentaje;
        let baseImponibleProd = parseFloat(prod.total);
        if (codigo_tarifa['codigo'] == 2 && iceBaseimponibleToral > 0) {
          baseImponibleProd += iceValorTotal;
        }
        itemimpuesto =
          (parseFloat(prod.impuestos[i].tarifa) / 100) * baseImponibleProd;
        itemimpuesto = Number(math.round(itemimpuesto, 2));
        totalitemimpuestos += itemimpuesto;
        codigo_tarifa['valor'] = math.round(itemimpuesto, 2);
        codigo_tarifa['baseImponible'] = baseImponibleProd;
        impuestosProducto.push(codigo_tarifa);
        let indiceexiste = impuestostotales.findIndex(
          e =>
            e.codigo === codigo_tarifa['codigo'] &&
            e.codigoPorcentaje === codigo_tarifa['codigoPorcentaje']
        );
        if (indiceexiste === -1) {
          const dicimpuesto = {
            codigo: codigo_tarifa['codigo'],
            codigoPorcentaje: codigo_tarifa['codigoPorcentaje'],
            descuentoAdicional: 0,
            baseImponible: baseImponibleProd,
            valor: math.round(itemimpuesto, 2)
          };
          const dicimpuestot = dicimpuesto;
          impuestostotales.push(dicimpuestot);
        } else {
          impuestostotales[indiceexiste].baseImponible += baseImponibleProd;
          impuestostotales[indiceexiste].valor += itemimpuesto;
          impuestostotales[indiceexiste].valor = math.round(
            impuestostotales[indiceexiste].valor,
            2
          );
        }
      }
      totalImpuestos += totalitemimpuestos;
    }

    // *********************************
    this.impuestos = math.round(totalImpuestos, 2).toString();
    // this.facturas.totalSinImpuestos = totalImpuestos.toString()
    const totalimporte = totalSinImpuestos + totalImpuestos;
    this.facturas.total = String(totalimporte); // sumat.toString()
  }

  crear() {
    $('#btn-print-section').show();
    $('#ruc_imp').html($('#ruc').val());
    $('#cliente_imp').html($('#cliente').val());
    $('#direccion_imp').html($('#direccion').val());
    $('#telefono_imp').html($('#telefono').val());
    $('#correo_imp').html($('#correo').val());
    $('#subTotal_imp').html($('#subTotal').val());
    $('#iva_imp').html($('#iva').val());
    $('#total_imp').html($('#total').val());



    // if (this.totalconimpuestos === 0) {
    //   alert('Ingrese al menos un producto');
    //   return;
    // }

    this.facturas['ruc'] = window.localStorage.getItem('user');
    this.facturas['direccionComprador'] = $('#direccion').val();
    this.facturas['identificacionComprador'] = $('#ruc').val();
    this.facturas['email'] = $('#correo').val();
    this.facturas['estab'] = '001';
    this.facturas['estado'] = 'EN PROCESAMIENTO';
    this.facturas['formaPago'] = '01';
    this.facturas['plazo'] = '0';
    this.facturas['unidadTiempo'] = 'dias';
    this.facturas['contribuyenteEspecial'] = '01';
    this.facturas['codDoc'] = '01';
    this.facturas['razonSocialComprador'] = $('#cliente').val();
    this.facturas['telefonoComprador'] = $('#telefono').val();

    console.log(this.totalconimpuestos);
    if (this.facturas['razonSocialComprador'].length === 0 ||
    this.facturas['identificacionComprador'].length === 0 ||
    this.facturas['direccionComprador'].length === 0 ||
    this.facturas['email'].length === 0 ||
    this.facturas['telefonoComprador'].length === 0 ||
    this.productoslista.length === 0
    ) {
      alert('Ingrese todos los datos');
      return;
    }

    this.facturas['idEmpresa'] = '000001'; // this.empresa['idEmpresa'] //'000001'
    this.facturas['type'] = 'factura';
    this.facturas['estado'] = 'EN PROCESAMIENTO';
    // this.facturas['ruc'] = this.ruc; //'1792239133001'//this.empresa['ruc']//'1792239133001'
    this.facturas['codDoc'] = '01';
    this.facturas['estab'] = '01'; // this.empresa['estab']
    this.facturas['ptoEmi'] = '01'; // this.empresa['ptoEmi']
    this.facturas['guiaRemision'] = '0';
    this.authService.getId(this.ruc).subscribe(data => {
      this.id = data;
      if (this.id) {
        console.log(this.id.secuencial);
        const secuencia = this.id.secuencial + 1;
        const secuencial = ('000000000' + secuencia.toString()).slice(-9);
        this.id =
          this.facturas['idEmpresa'] +
          this.facturas['estab'] +
          this.facturas['ptoEmi'] +
          secuencial;
        console.log(this.id);
        this.facturas['_id'] = this.id;
        this.facturas['secuencial'] = secuencia; // this.id.toString().substr(12, 21)
      } else {
        this.id =
          this.facturas['idEmpresa'] +
          this.facturas['estab'] +
          this.facturas['ptoEmi'] +
          '000000001';
        this.facturas['_id'] = this.id;
        this.facturas['secuencial'] = 1;
      }

      this.facturas['direccionComprador'] = 'Av 10 de Agosto';
      let totalSinImpuestos = 0;
      let totalImpuestos = 0;
      // const arrtotalConImpuestos = []
      const impuestostotales = [];
      // const arrimpuestosct = []
      const facturadetalle = [];
      for (const c in this.productoslista) {
        const prod = this.productoslista[c];
        totalSinImpuestos += parseFloat(prod.total);
        let totalitemimpuestos = 0;
        let itemimpuesto = 0;
        const impuestosProducto = [];
        let iceValorTotal = 0;
        let iceBaseimponibleToral = 0;
        // esto es para sacar totales del ice
        // tslint:disable-next-line:forin
        for (const h in prod.impuestos) {
          let iceitemimpuesto = 0;
          const baseImponibleProd = parseFloat(prod.total);
          const ice = {};
          iceitemimpuesto =
            (parseFloat(prod.impuestos[h].tarifa) / 100) * baseImponibleProd;
          iceitemimpuesto = Number(math.round(iceitemimpuesto, 2));
          ice['codigo'] = prod.impuestos[h].codigo;
          ice['tarifa'] = prod.impuestos[h].tarifa;
          ice['codigoPorcentaje'] = prod.impuestos[h].codigoPorcentaje;
          ice['valor'] = math.round(iceitemimpuesto, 2);
          ice['baseImponible'] = parseFloat(prod.total);
          if (ice['codigo'] == 3) {
            iceValorTotal += ice['valor'];
            iceBaseimponibleToral += ice['baseImponible'];
          }
        }

        // tslint:disable-next-line:forin
        for (let i in prod.impuestos) {
          let codigo_tarifa = {};
          codigo_tarifa['codigo'] = prod.impuestos[i].codigo;
          codigo_tarifa['tarifa'] = prod.impuestos[i].tarifa;
          codigo_tarifa['codigoPorcentaje'] =
            prod.impuestos[i].codigoPorcentaje;
          let baseImponibleProd = parseFloat(prod.total);
          if (codigo_tarifa['codigo'] == 2 && iceBaseimponibleToral > 0) {
            baseImponibleProd += iceValorTotal;
          }

          itemimpuesto =
            (parseFloat(prod.impuestos[i].tarifa) / 100) * baseImponibleProd;
          itemimpuesto = Number(math.round(itemimpuesto, 2));
          totalitemimpuestos += itemimpuesto;

          codigo_tarifa['valor'] = math.round(itemimpuesto, 2);
          codigo_tarifa['baseImponible'] = baseImponibleProd;
          impuestosProducto.push(codigo_tarifa);
          let indiceexiste = impuestostotales.findIndex(
            e =>
              e.codigo === codigo_tarifa['codigo'] &&
              e.codigoPorcentaje === codigo_tarifa['codigoPorcentaje']
          );
          if (indiceexiste === -1) {
            const dicimpuesto = {
              codigo: codigo_tarifa['codigo'],
              codigoPorcentaje: codigo_tarifa['codigoPorcentaje'],
              descuentoAdicional: 0,
              baseImponible: baseImponibleProd,
              valor: math.round(itemimpuesto, 2)
            };
            const dicimpuestot = dicimpuesto;
            // dicimpuestot.baseImponible = totalSinImpuestos
            impuestostotales.push(dicimpuestot);
          } else {
            impuestostotales[indiceexiste].baseImponible += baseImponibleProd;
            impuestostotales[indiceexiste].valor += itemimpuesto;
            impuestostotales[indiceexiste].valor = math.round(
              impuestostotales[indiceexiste].valor,
              2
            );
            console.log(
              'asasasasasasas' + impuestostotales[indiceexiste].valor.toString()
            );
          }
        }
        const detalle = {
          codigoPrincipal: prod.codigoprincipal,
          codigoAuxiliar: prod.codigosecundario,
          cantidad: prod.cantidad,
          precioUnitario: prod.valoru,
          valor: prod.valorunitario,
          descuento: 0,
          descripcion: prod.descripcion,
          precioTotalSinImpuesto: prod.valort,
          detallesAdicionales: [
            {
              nombre: '0',
              valor: '0'
            }
          ],
          impuestos: impuestosProducto
        };
        facturadetalle.push(detalle);
        totalImpuestos += totalitemimpuestos;
      }
      // console.log(impuestostotales)
      console.log(totalImpuestos);
      console.log(totalSinImpuestos);
      const importeTotal = totalImpuestos + totalSinImpuestos;
      this.facturas['totalSinImpuestos'] = math
        .round(totalSinImpuestos, 2)
        .toString();
      this.facturas['totalDescuento'] = '0'; // a futuro campo adicional en el formulario
      this.facturas['totalConImpuestos'] = impuestostotales;
      this.facturas['propina'] = '0';
      this.facturas['importeTotal'] = math.round(importeTotal, 2);

      // if (this.productoslistaFP.length > 0) {
      //   this.facturas['pagos'] = this.anArrayFP
      // }
      // else {
      this.facturas['pagos'] = [
        {
          formaPago: '01', // this.facturas.formaPago,
          total: math.round(importeTotal, 2),
          plazo: 0,
          unidadTiempo: 'dias'
        }
      ];
      // }

      this.facturas['valorRetIva'] = '0';
      this.facturas['valorRetRenta'] = '0';

      this.facturas['facturaDetalle'] = facturadetalle;

      this.facturas['infoAdicional'] = [
        {
          nombre: '0',
          valor: '0'
        }
      ];
      // this.facturas['email'] = 'juanperez@gmail.com'
      this.facturas['observacion'] = '';
      this.facturas['guiaRemision'] = '001-001-000000001';
      this.facturas['contribuyenteEspecial'] = this.empresa['idEmpresa'];

      const currentdate = new Date();
      const datetime =
        currentdate.getDate() +
        '/' +
        (currentdate.getMonth() + 1) +
        '/' +
        currentdate.getFullYear();
      this.facturas.fechaEmision = datetime;
      console.log(this.facturas.fechaEmision);
      delete this.facturas['total'];
      delete this.facturas['formaPago'];
      console.log(this.facturas);

      // tslint:disable-next-line:no-shadowed-variable
      this.authService.insertFactura(this.facturas).subscribe(data => {
        console.log(data);
        alert ('Factura almacenada');
        this.limpiar();
      });

      console.log(math.round(3.567899, 2));
      // this.presentAlert('Tango', 'Su Factura fue enviada exitosamente!', 'Aceptar')
      // this.navCtrl.push(HomePage);
    });
  }
}
