import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../servicios/auth.service';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { Subject } from 'rxjs'; // dataTables
import { ToastrService } from 'ngx-toastr';
import { FlashMessagesService } from 'angular2-flash-messages';
import { environment } from '../../../environments/environment';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-adm-products',
  templateUrl: './adm-products.component.html',
  styleUrls: ['./adm-products.component.css'],
})
export class AdmProductsComponent implements OnInit {
  public email: string;
  img;
  loader = false;
  msn;
  trueimg;
  myimg;

  imagename = 'prueba.png';

  allProducts: any = []; // dataTables
  dtOptions: DataTables.Settings = {}; // dataTables
  dtTrigger: Subject<any> = new Subject(); // dataTables

  product: any = [];

  tallaArray: Array<any> = [{ color: '' }];
  newTalla: any = {};
  colorArray: Array<any> = [{ color: '' }];
  newColor: any = {};

  allCategories: any;

  constructor(
    public authService: AuthService,
    private route: ActivatedRoute,
    // tslint:disable-next-line:no-shadowed-variable
    public AppComponent: AppComponent,
    public router: Router,
    public toastr: ToastrService,
    private flashMessagesService: FlashMessagesService
  ) {}

  // dataTables
  ngOnInit(): void {
    console.log(this.typeUserLocalStorage());
    if (this.typeUserLocalStorage() === 'cliente') {
      this.allProductsDataTables();
    } else if (this.typeUserLocalStorage() === 'proveedor') {
      this.allProductsDataTablesProvider();
    } else {
      this.allProductsDataTables();
    }

    this.authService.loadDataCategories().subscribe((result) => {
      this.allCategories = result;
      // console.log(this.allCategories);
    });
  }

  allProductsDataTables() {
    this.dtOptions = this.AppComponent.optionsDatatables();
    this.authService.loadDataProducts().subscribe((result) => {
      this.allProducts = result;
      this.dtTrigger.next();
      console.log(this.allProducts);
    });
  }

  allProductsDataTablesProvider() {
    this.dtOptions = this.AppComponent.optionsDatatables();
    this.authService
      .loadDataProductsProvider(localStorage.getItem('user'))
      .subscribe((result) => {
        this.allProducts = result;
        this.dtTrigger.next();
        // console.log(this.allProducts);
      });
  }

  openEditData(id) {
    this.authService.getProductById(id).subscribe((result) => {
      this.product = result;
      this.tallaArray = this.product[0].tallas;
      this.colorArray = this.product[0].color;
    });
    $('#editModal').modal('show');
  }

  openAddData() {
    $('#addModal').modal('show');
    this.email = localStorage.getItem('user');
  }

  cargaArchivo(ev) {
    const img: any = ev.target;
    this.img = img;
    const uniqueNumber = new Date().getTime().toString();
    const extension = img.files[0]['name'].split('.')[1];
    this.imagename = uniqueNumber.toString() + '.' + extension ;
    if (img.files.length > 0) {
      this.loader = true;
      const form = new FormData();
      form.append('image', img.files[0]);
      form.append('nombreimagen', this.imagename);
      this.msn =
        img.files[0]['name'] +
        ' Peso:' +
        Math.round(img.files[0]['size'] / 1024) +
        'Kb';
      if (img.files[0].size > 307200) {
        alert('El archivo supera el limite de 3MB.');
        return;
      }

      this.authService.uploadImage(form).subscribe(
        (resp) => {
          this.loader = false;
          alert('La imagen se cargó correctamente.');
          let imagename = '';
          imagename =
            environment.tiendaConfig.BASE_API_URL +
            'imageproducts/' +
            this.imagename;
          $('#imageServer').html(
            '<img class="img-fluid" src="' +
              imagename +
              '" alt="Imagen" height="60">'
          );
        },
        (error) => {
          this.loader = false;
          alert(
            'Ocurrió un error al cargar la imagen.');
        }
      );
    }
  }

  create() {
    const json = $('#frmAdd').serializeJSON();
    json['publicidad'] = [];
    const file = $('#image')[0].files[0];
    json['image'] =
      environment.tiendaConfig.BASE_API_URL + 'imageproducts/' + this.imagename;
    // console.log(file.name);
    this.authService.createProductPost(json).subscribe((result) => {
      this.product = result;
      this.router.navigate(['/loading']).then(() => {
        this.router.navigate(['/adm-products']);
      });
      $('#addModal').modal('hide');
      if (this.product.msg === 'si') {
        alert('Creado correctamente.');
      } else {
        alert('Ocurrió un erro al crear.');
      }
    });
  }

  update() {
    const id = $('#id').val();
    const json = $('#frmUpdate').serializeJSON();
    this.authService.updateProductPut(json, id).subscribe((result) => {
      this.product = result;
      this.router.navigate(['/loading']).then(() => {
        this.router.navigate(['/adm-products']);
      });
      $('#editModal').modal('hide');
      if (this.product.msg === 'si') {
        alert('Actualizado correctamente.');
        // this.toastr.success('Actualizado correctamente.');
      } else {
        alert('Ocurrió un error al actualizar.');
        // this.toastr.error('Ocurrió un error al actualizar.');
      }
    });
  }

  delete(id) {
    const c = confirm('Esta seguro de eliminar el registro?');
    if (c === true) {
      this.authService.deleteProduct(id).subscribe((result) => {
        $('#' + id).remove();
        this.product = result;
        this.router.navigate(['/loading']).then(() => {
          this.router.navigate(['/adm-products']);
        });
        if (this.product.status === 'OK') {
          alert('Eliminado correctamente.');
        } else {
          alert('Ocurrió un error al eliminar.');
        }
      });
    }
  }

  addTalla() {
    this.tallaArray.push(this.newTalla);
    this.newTalla = {};
  }

  removeTalla(index) {
    this.tallaArray.splice(index, 1);
  }

  addColor() {
    this.colorArray.push(this.newColor);
    this.newColor = {};
  }

  removeColor(index) {
    this.colorArray.splice(index, 1);
  }

  typeUserLocalStorage() {
    for (const propiedad in localStorage) {
      if (localStorage.hasOwnProperty(propiedad)) {
        return localStorage.getItem('type_user');
      } else {
        return false;
      }
    }
  }
}
