import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../servicios/auth.service';

@Component({
  selector: 'app-profesionales',
  templateUrl: './profesionales.component.html',
  styleUrls: ['./profesionales.component.scss']
})
export class ProfesionalesComponent implements OnInit {
  public categories: any;
  constructor(public authService: AuthService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.getAllCategories();
}

getAllCategories(){
  this.authService.getAllCategories().subscribe(
    data => { this.categories = data},
    err => console.error(err),
    () => console.log(this.categories)
  );
}

getBaseURL(){
  return document.getElementsByTagName('base')[0].href;
}

}

