import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../servicios/auth.service';
import { OrderComponent } from '../order/order.component';
//import { FlashMessagesService } from 'angular2-flash-messages';
import { HttpClient } from '@angular/common/http'; //http
import { AppComponent } from '../../app.component';
import { ToastrService } from 'ngx-toastr';

declare var jQuery:any;
declare var $:any;

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  product;
  data:any = [];
  public itemsCart;
  recentProductName = "Ninguno";
  public itemsToCart;
  idProduct = "";
  products;

  constructor(
    public authService: AuthService, 
    private route: ActivatedRoute, 
    private http: HttpClient,
    public AppComponent: AppComponent,
    public toastr: ToastrService
  ) { 

    }

  ngOnInit() {
    this.existProductToCart();
    this.getProductById();
  }

  getProductById(){
    this.authService.getProductById(this.route.snapshot.paramMap.get('id')).subscribe(
        data => { this.product = data},
        err => console.error(err),
        () => console.log(this.product)
      );
  }

  productSelected(nombreProducto){
    this.recentProductName = nombreProducto;
    this.itemsCart.push(nombreProducto);
    console.log(this.itemsCart);
    
  }

  addProductToCart(idProduct,nombre,price,image){
    let color = $("input[name*='color']:checked" ).val();
    let talla = $('#tallas').val();
    let quantityProductCart = Number($('#slcQuantity').val());
    let itemsArray = localStorage.getItem('productsCart') ? JSON.parse(localStorage.getItem('productsCart')) : [];
	  itemsArray.push({'idProduct':idProduct,'nombre':nombre,'quantityProductCart': quantityProductCart,'price':price ,'image':image,'color':color, 'talla':talla});
    localStorage.setItem('productsCart', JSON.stringify(itemsArray));
    this.AppComponent.numberItemsFromCart();
    this.existProductToCart();

    this.authService.addProductCartDB();//actualiza los datos del carro en la BD
    /*ok
    let quantityProductCart = Number($('#slcQuantity').val());
    let itemsCart = {'quantityProductCart':  quantityProductCart};
    let itemsCartLocal = JSON.parse(localStorage.getItem(idProduct));
    if(localStorage.getItem(idProduct)){
      this.flashMensaje.show("El producto ya se encuentra agregado en el carrito, se incrementó la cantidad.", {cssClass: 'alert-success', timeout: 8000});
      itemsCart = {'quantityProductCart':  Number(itemsCartLocal.quantityProductCart)+quantityProductCart};
    }
    localStorage.setItem(idProduct, JSON.stringify(itemsCart));
    */
    //this.itemsCart.push(localStorage.getItem('productsCart'));
    
  }

  existProductToCart():void{
    let itemsArray = localStorage.getItem('productsCart') ? JSON.parse(localStorage.getItem('productsCart')) : [];
    let idProductGet = this.route.snapshot.paramMap.get('id');
    let verifyProduct:boolean = false;
    console.log(idProductGet);
    for (let position in itemsArray) {
      if(idProductGet == itemsArray[position].idProduct){
        $('#divAddProductButton').hide();
        $('#divViewCart').show();
        verifyProduct = true;
      }
    }
    if(verifyProduct){
      this.toastr.success('El producto se agregó en el carrito.');
    }
    
  }


}
