import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../servicios/auth.service';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  productsCart;
  idProduct = '';
  price = '';
  image = '';

  constructor(public authService: AuthService,
              private route: ActivatedRoute,
              public appComponent: AppComponent,
              public router: Router
            ) { }

  ngOnInit() {
    // localStorage.clear();
    // this.addProductToCart();
    // this.subtotalFromCart();
    this.dataFromCart();
    // this.deleteItemFromCart(this.idProduct);
    this.updateItemFromCart(this.idProduct, this.price, this.image);

  }

  dataFromCart() {
    for (const propiedad in localStorage) {
      if (localStorage.hasOwnProperty(propiedad)) {
        this.productsCart = JSON.parse(localStorage.getItem('productsCart'));
      }
    }
    // console.log(this.productsCart.length);
    return this.productsCart;
  }

  subtotalFromCart(): void {
    let subtotalFromCart = 0;
    const detailProductArray = JSON.parse(localStorage.getItem('productsCart'));
    // console.log('typeof retrievedMovieArray: ' + typeof detailProductArray);
    // console.log('retrievedMovieArray:');
    for (const position of Object.keys(detailProductArray)) {
      // console.log(p +':'+ detailProductArray[p].idProduct +'|'+detailProductArray[p].price);
      subtotalFromCart += (Number(detailProductArray[position].price) * Number(detailProductArray[position].quantityProductCart));
    }
    $('.subtotalFromCart').html(Number(subtotalFromCart).toFixed(2));
    //subtotal localStorage
    localStorage.setItem('st', JSON.stringify(subtotalFromCart));
    const st = localStorage.getItem('st')
  }

  deleteItemFromCart(idProduct) {
    const c = confirm('Esta seguro de eliminar el producto?');
    if (c === true) {
      const itemsArray = localStorage.getItem('productsCart') ? JSON.parse(localStorage.getItem('productsCart')) : [];
      for (const position in itemsArray) {
        if (idProduct === itemsArray[position].idProduct) {
          itemsArray.splice(position, 1);
        }
      }
      localStorage.setItem('productsCart', JSON.stringify(itemsArray));
      this.appComponent.numberItemsFromCart();
      this.subtotalFromCart();
      $('#product_' + idProduct).fadeOut(300, function() { $(this).remove(); });
      this.router.navigate(['/loading']).then(() =>  {this.router.navigate(['/cart']); });
      alert('Producto eliminado de carrito.');
      this.authService.addProductCartDB(); // actualiza los datos del carro en la BD
    }
  }

  updateItemFromCart(idProduct, price, image) {
    const quantityProductCart = Number($('#quantity_' + idProduct).val());
    if (quantityProductCart <= 0 ) {
       alert('La cantidad ingresada no es correcta.');
    }
    const itemsArray = localStorage.getItem('productsCart') ? JSON.parse(localStorage.getItem('productsCart')) : [];
    for (const position in itemsArray) {
      if (idProduct === itemsArray[position].idProduct) {
        itemsArray.splice(position, 1, {
          'idProduct': itemsArray[position].idProduct,
          'quantityProductCart': quantityProductCart,
          'nombre': itemsArray[position].nombre,
          'color': itemsArray[position].color,
          'talla': itemsArray[position].talla,
          'price': itemsArray[position].price,
          'image': itemsArray[position].image
          });
      }
    }
    // console.log(JSON.stringify(itemsArray));
    localStorage.setItem('productsCart', JSON.stringify(itemsArray));
    //alert('Se actualizó la cantidad del producto.');
    this.appComponent.numberItemsFromCart();
    this.subtotalFromCart();
    this.authService.addProductCartDB(); // actualiza los datos del carro en la BD
  }

  checkValue(idProduct) {
    const quantityProductCart = Number($('#quantity_' + idProduct).val());
    if (quantityProductCart <= 0) {
      $('#quantity_' + idProduct).val();
      alert('La cantidad ingresada no es correcta.');
    }
  }

}
