import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../servicios/auth.service';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
  datos = {
    nombres: '',
    cedula:'',
    direccion: '',
    telefonos: '',
    pais: '',
    ciudad: '',
    calle1: '',
    calle2: '',
    numcasa: '',
    correo: '',
    ruc: '',
    razonsocial: '',
    nombrecomercial: '',
    secuencia: '',
    codigoestablecimiento: '',
    codigopuntoemision: '',
    dirmatriz: '',
    obligadoContabilidad: '',
    pwdfirma: '',
    persona_recibe: '',
    
  };
  msn;
  usuario;
  data: any = [];
  idProductLocalStorage: any;

  frmRevisaOrden: FormGroup;

  constructor(
    public authService: AuthService,
    private route: ActivatedRoute,
    public Appcomponent: AppComponent,
    public router: Router,
    private fb: FormBuilder
  ) {

    this.frmRevisaOrden = this.fb.group({
      cedula: ['', Validators.required],
      nombres: ['', Validators.required],
      telefono: ['', Validators.required],
      correo: ['', Validators.required],
      pais: ['', Validators.required],
      ciudad: ['', Validators.required],
      calle_principal: ['', Validators.required],
      calle_secundaria: ['', Validators.required],
      numeracion: ['', Validators.required],
      persona_recibe: ['', Validators.required],
      /*tipo_compra: ['', Validators.required]*/
    });

  }

  ngOnInit() {
    this.usuario = localStorage.getItem('user');
    this.authService.proveedorcedula(this.usuario).subscribe(data => {
    this.datos.nombres = data['nombres'];
    this.datos.persona_recibe = data['persona_recibe'];
    this.datos.cedula = data['cedula'];
    this.datos.direccion = data['direccion'];
    this.datos.telefonos = data['telefonos'];
    this.datos.correo = data['correo'];
    this.datos.pais = data['pais'];
    this.datos.ciudad = data['ciudad'];
    this.datos.calle1 = data['calle1'];
    this.datos.calle2 = data['calle2'];
    this.datos.numcasa = data['numcasa'];
    this.datos.ruc = data['ruc'];
    this.datos.razonsocial = data['razonsocial'];
    this.datos.nombrecomercial = data['nombrecomercial'];
    this.datos.secuencia = data['secuencia'];
    this.datos.codigoestablecimiento = data['codigoestablecimiento'];
    this.datos.codigopuntoemision = data['codigopuntoemision'];
    this.datos.dirmatriz = data['dirmatriz'];
    this.datos.obligadoContabilidad = data['obligadoContabilidad'];
    this.datos.pwdfirma = data['pwdfirma'];
    });

  }
  

  /*
  validacion(){
    const frm = this.frmRevisaOrden.value;
    if(frm.cedula===''){
      alert("Campo obligatorio");
    }
  }
  */
  /*
  idProducLocaStorage(){
    let idProductLocalStorage = new Array();
    let detailProductArray = JSON.parse(localStorage.getItem('productsCart'));
    for (let position in detailProductArray) {
      idProductLocalStorage.push({"id":detailProductArray[position].idProduct, "cantidad":detailProductArray[position].quantityProductCart,
      "color":detailProductArray[position].color, "tallas":detailProductArray[position].talla});
    }
    //console.log(idProductLocalStorage);
    return idProductLocalStorage;
  }

  subtotalFromCart(){
    let subtotalFromCart = 0;
    let detailProductArray = JSON.parse(localStorage.getItem('productsCart'));
    for (let position in detailProductArray) {
      subtotalFromCart += (Number(detailProductArray[position].price)*Number(detailProductArray[position].quantityProductCart));
    }
    if($("#tipo_compra").val()===1 || $("#tipo_compra").val()===2){
      this.toastr.warning('El tipo de pago seleccionado tiene un costo adicional de $2.00');
    }
    return (subtotalFromCart);
  }
  */
 
  guardar(): void {
    this.authService.updateProviderPut(this.datos, this.usuario).subscribe(data => {
      
    });
  }


  

  enviarOrden(param, tipo) {
    const json = this.Appcomponent.arrayToJSON('frmAdd');
    json['productos'] = this.authService.idProducLocaStorage();
    json['subtotal'] = this.authService.subtotalFromCart();
    json['estado'] = param;
    json['tipo_compra'] = tipo;

    this.authService.saveOrder(json).subscribe(
      result => {
        this.data = result;
      }
    );
 }

  regresar() {
    this.router.navigate(['/loading']).then(() => { this.router.navigate(['/cart']); });
  }

}
