import { Component } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http'; // http
import { CATCH_STACK_VAR } from '@angular/compiler/src/output/output_ast';
import { ToastrService } from 'ngx-toastr';
import { LoadingComponent } from './componentes/loading/loading.component';
import { each } from 'jquery';


// http
interface UserResponse {
  pwd: string;
  usuario: string;
  tipo: string;
}

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  Loading: boolean;
  constructor(
    public toastr: ToastrService
  ) {
this.Loading = true;
this.Loading = false;
  }
  /*
  constructor(private http: HttpClient){ //http
  }

  //http
  ngOnInit(): void{
    this.http.get<UserResponse>('https://tangotienda.herokuapp.com/users').subscribe(data=>{
      console.log(data[0].pwd);
      console.log(data);
    },(err: HttpErrorResponse)=>{
      if(err.error instanceof Error){
        console.log("Ocurrio un error en el cliente.");
      }else{
        console.log("Ocurrio un error en el servidor.");
      }
    });

    const req= this.http.post('https://tangotienda.herokuapp.com/signup',{
      usuario: 'Edison 2',
      nombres: 'edison 2',
      pwd: '321'
    }).subscribe(res=>{
      console.log(res)
      },err=>{
        console.log("Ocurrio un error");
      });

  }
  */

  numberItemsFromCart(): void {
    $('#num-tems,#numberItemsFormCart').fadeOut();
    let quantityProductCart = 0;
    const itemsArray = localStorage.getItem('productsCart') ? JSON.parse(localStorage.getItem('productsCart')) : [];
    for (const position of Object.keys(itemsArray)) {
      quantityProductCart += itemsArray[position].quantityProductCart;
    }
    $('#numberItemsFormCart').fadeOut('slow').fadeIn('slow').fadeOut('slow').fadeIn('slow').html(quantityProductCart);
    $('#num-tems').slideUp('slow').slideDown('slow').fadeOut('slow').fadeIn('slow').html(quantityProductCart);
    //console.log(itemsArray);
  }

  arrayToJSON(idForm) {
    const o = {};
    const a = $('#' + idForm).serializeArray();

    $.each(a, function () {
      if (o[this.name]) {
        if (!o[this.name].push) {
          o[this.name] = [o[this.name]];
        }
        o[this.name].push(this.value || '');
      } else {
        o[this.name] = this.value || '';
      }
    });

    console.log(o);
    return o;
  }

  optionsDatatables() {
    return {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        processing: 'Procesando...',
        search: 'Buscar:',
        lengthMenu: 'Mostrar _MENU_ elementos',
        info: 'Mostrando desde _START_ al _END_ de _TOTAL_ elementos',
        infoEmpty: 'Mostrando ningún elemento.',
        infoFiltered: '(filtrado _MAX_ elementos total)',
        infoPostFix: '',
        loadingRecords: 'Cargando registros...',
        zeroRecords: 'No se encontraron registros',
        emptyTable: 'No hay datos disponibles',
        paginate: {
          first: 'Primero',
          previous: 'Anterior',
          next: 'Siguiente',
          last: 'Último'
        },
        aria: {
          sortAscending: ': Activar para ordenar la tabla en orden ascendente',
          sortDescending: ': Activar para ordenar la tabla en orden descendente'
        }
      }
    };
  }

}

