import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { DatePipe } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeEs);

import { AppRoutingModule } from './app-routing.module';

// import {} from '';
import { HttpClientModule } from '@angular/common/http'; // http

import { FlashMessagesModule } from 'angular2-flash-messages';
import { FlashMessagesService } from 'angular2-flash-messages';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';

/*
import {AngularFireModule} from 'angularfire2';
import {AngularFireAuthModule} from 'angularfire2/auth';
import {environment} from '../environments/environment';
*/

import {AuthService} from './servicios/auth.service';
// import {AuthGuard} from './guards/auth.guard';

import { MaterialModule } from './material';
import { FlexLayoutModule } from '@angular/flex-layout';

import { DataTablesModule } from 'angular-datatables';

import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { OwlModule } from 'ngx-owl-carousel';
import { NgxPrintModule } from 'ngx-print';

import { AppComponent } from './app.component';
import { HomePageComponent } from './componentes/home-page/home-page.component';
import { NavbarComponent } from './componentes/navbar/navbar.component';
import { RegisterPageComponent } from './componentes/register-page/register-page.component';
import { LoginPageComponent } from './componentes/login-page/login-page.component';
import { PrivatePageComponent } from './componentes/private-page/private-page.component';
import { NotFoundPageComponent } from './componentes/not-found-page/not-found-page.component';
import { ProfilePageComponent } from './componentes/profile-page/profile-page.component';
import { CategoriesComponent } from './componentes/categories/categories.component';
import { ProductsComponent } from './componentes/products/products.component';
import { ProductComponent } from './componentes/product/product.component';
import { CartComponent } from './componentes/cart/cart.component';
import { AdmProductsComponent } from './componentes/adm-products/adm-products.component';
import { AdmCategoriesComponent } from './componentes/adm-categories/adm-categories.component';
import { AdmUsersComponent } from './componentes/adm-users/adm-users.component';
import { SearchComponent } from './componentes/search/search.component';
import { AdmProviderComponent } from './componentes/adm-provider/adm-provider.component';
import { LoadingComponent } from './componentes/loading/loading.component';
import { FooterComponent } from './componentes/footer/footer.component';
import { OrderComponent } from './componentes/order/order.component';
import { AdmOrderProviderComponent } from './componentes/adm-order-provider/adm-order-provider.component';
import { InvoiceComponent } from './componentes/invoice/invoice.component';
import { FactProductosComponent } from './componentes/fact-productos/fact-productos.component';
import { ProductsProviderComponent } from './componentes/products-provider/products-provider.component';
import { AdmPublicidadComponent } from './componentes/adm-publicidad/adm-publicidad.component';
import { ConfiguracionComponent } from './componentes/configuracion/configuracion.component';
import { RedirectComponent } from './componentes/redirect/redirect.component';
import { TerminosycondicionesComponent } from './componentes/terminosycondiciones/terminosycondiciones.component';
import { InstructivoComponent } from './componentes/instructivo/instructivo.component';
import { BuscarComponent } from './componentes/buscar/buscar.component';
import { ProfesionalesComponent } from './componentes/profesionales/profesionales.component';
import { BotonpagosComponent } from './componentes/botonpagos/botonpagos.component';
import { AdmOrderAdminComponent } from './componentes/adm-order-admin/adm-order-admin.component';
import { PedidosComponent } from './componentes/pedidos/pedidos.component';
import { HeroComponent } from './componentes/hero/hero.component';



@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    NavbarComponent,
    RegisterPageComponent,
    LoginPageComponent,
    PrivatePageComponent,
    NotFoundPageComponent,
    ProfilePageComponent,
    CategoriesComponent,
    ProductsComponent,
    ProductComponent,
    CartComponent,
    AdmProductsComponent,
    AdmCategoriesComponent,
    AdmUsersComponent,
    SearchComponent,
    AdmProviderComponent,
    LoadingComponent,
    FooterComponent,
    OrderComponent,
    AdmOrderProviderComponent,
    InvoiceComponent,
    FactProductosComponent,
    ProductsProviderComponent,
    AdmPublicidadComponent,
    ConfiguracionComponent,
    RedirectComponent,
    TerminosycondicionesComponent,
    InstructivoComponent,
    BuscarComponent,
    ProfesionalesComponent,
    
    BotonpagosComponent,
    
    AdmOrderAdminComponent,
    PedidosComponent,
    HeroComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    // AngularFireModule.initializeApp(environment.firebaseConfig),
    // AngularFireAuthModule,
    MaterialModule,
    FlexLayoutModule,
    FlashMessagesModule,
    HttpClientModule, // http
    DataTablesModule,
    CommonModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    InfiniteScrollModule,
    OwlModule,
    NgxPrintModule,
    NgbModule.forRoot()
  ],
  providers: [
    AuthService,
    // AuthGuard,
    FlashMessagesService,
    DatePipe,
    {provide: LOCALE_ID, useValue: 'es'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
