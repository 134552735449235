import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../servicios/auth.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {
  public categories: any;

  constructor(public authService: AuthService, private route: ActivatedRoute) { }

  ngOnInit() {
      this.getAllCategories();
  }

  getAllCategories(){
    this.authService.getAllCategories().subscribe(
      data => { this.categories = data},
      err => console.error(err),
      () => console.log(this.categories)
    );
  }

  getBaseURL(){
    return document.getElementsByTagName('base')[0].href;
  }

}
