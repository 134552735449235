import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../servicios/auth.service';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { Subject } from 'rxjs'; // dataTables
import { ToastrService } from 'ngx-toastr';
import { FlashMessagesService } from 'angular2-flash-messages';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-adm-order-provider',
  templateUrl: './adm-order-provider.component.html',
  styleUrls: ['./adm-order-provider.component.css']
})
export class AdmOrderProviderComponent implements OnInit {
  
  allData: any = []; // dataTables
  dtOptions: DataTables.Settings = {}; // dataTables
  dtTrigger: Subject<any> = new Subject(); // dataTables

  data: any = [];
  tallaArray: Array<any> = [{color: ''}];
  newTalla: any = {};
  colorArray: Array<any> = [{color: ''}];
  newColor: any = {};
  allCategories: any;

  proveedor: any = (localStorage.getItem('user'));
  productos: any = [];
  producto: any = [];

  constructor(public authService: AuthService,
              private route: ActivatedRoute,
              public appComponent: AppComponent,
              public router: Router,
              public toastr: ToastrService,
              private flashMessagesService: FlashMessagesService
            ) {
  }

  // dataTables
  ngOnInit(): void {
    console.log(this.typeUserLocalStorage());
    if (this.typeUserLocalStorage() === 'proveedor') { // cliente
      this.allOrderProviderDataTables();
    }
    this.authService.loadDataCategories().subscribe( result => {
      this.allCategories = result;
      // console.log(this.allCategories);
    });
  }

  allOrderProviderDataTables() {
    this.dtOptions = this.appComponent.optionsDatatables();
    this.authService.
    loadDataOrderProvider(localStorage.getItem('user'))
    .subscribe( result => {
      this.allData = result;
      this.dtTrigger.next();
      console.log(this.allData);
    });

  }

  openEditData(id) {
    // console.log(id);
    this.authService.getOrderProviderById(id).subscribe( result => {
      this.data = result;
      this.productos = this.data.productos.filter( prod => prod.proveedor === this.proveedor );
      console.log(this.productos);
      console.log(this.productos.length);
    });
    $('#editModalorder').modal('show');
  }

  openAddData() {
    $('#addModal').modal('show');
  }

  update() {
    const id = $('#id').val();
    const json = $('#frmUpdate').serializeJSON();
    this.authService.updateProductPut(json, id).subscribe(
      result => {
        this.data = result;
        this.router.navigate(['/loading']).then(() => {this.router.navigate(['/adm-products']); });
        $('#editModal').modal('hide');
        if (this.data.msg === 'si') {
          this.flashMessagesService.show('Actualizado correctamente.', { cssClass: 'alert-success', timeout: 3000 });
          // this.toastr.success('Actualizado correctamente.');
        } else {
          this.flashMessagesService.show('Ocurrió un error al actualizar.', { cssClass: 'alert-danger', timeout: 3000 });
          // this.toastr.error('Ocurrió un error al actualizar.');
        }
      }
    );
  }

  typeUserLocalStorage() {
      for (const propiedad in localStorage) {
        if (localStorage.hasOwnProperty(propiedad)) {
          return localStorage.getItem('type_user');
        } else {
          return false;
        }
      }
  }

  updateStatusOrder(id) {
    this.authService.updateStatusOrderPut({'estado': $('#estado').val()}, id).subscribe( result => {
      this.data = result;
      if (this.data.status = 'OK') {
        //this.toastr.success('Actualizado correctamente.');
        this.router.navigate(['/loading']).then(() => {this.router.navigate(['/configuracion']); });
      } else {
        //this.toastr.error('Ocurrió un error al actualizar.');
      }
      $('#editModalorder').modal('hide');
      // console.log(this.data);
    });
  }

}
