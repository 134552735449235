import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthService } from '../../servicios/auth.service';
import { Router } from '@angular/router';

import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { data } from 'jquery';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  images: Array<string>;
  allData: any = []; // dataTables
  settings;
  public product;
  products;
  public categories: any;

  constructor(
    private _http: HttpClient,
    public authService: AuthService,
    public router: Router
  ) {

  }

  ngOnInit() {
    this.getAllProducts();
    this.getAllCategories();
    
    this.authService.loadDataProviders().subscribe(result => {
      this.allData = result;
    });

    this.authService.getSettings().subscribe(data => {
      this.settings = data;
    });
  }
  getAllCategories() {
    this.authService.loadDataCategories().subscribe(
      data => { this.categories = data; },
      err => console.error(err)/*,
      () => console.log(this.categories)*/
    );
  }


  search() {
    if (this.router.url.indexOf('search') > 0) {
      this.product = $('#search').val();
      this.router.navigate(['/loading']).then(() => { this.router.navigate(['/search/' + this.product]); });
    } else {
      this.product = $('#search').val();
      this.router.navigate(['/search/' + this.product]);
    }
  }
  getAllProducts() {
    
      this.authService.getAllProducts().subscribe(data => { 
        this.products = data
        this.products.sort(function(a, b) {
          return -1;
        });
        console.log(this.products)
      },
        err => console.error(err),
        
        //() => console.log(this.products)
      );
    

  }
}




