import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../servicios/auth.service';
import { ToastrService } from 'ngx-toastr';
import * as math from 'mathjs'

@Component({
  selector: 'app-fact-productos',
  templateUrl: './fact-productos.component.html',
  styleUrls: ['./fact-productos.component.css']
})
export class FactProductosComponent implements OnInit {
  iva = false;
  ice = false;
  valorice = 5;
  descripcion;
  preciounitario = 0;
  codigoprincipal;

  constructor(public srv: AuthService, public toastr: ToastrService) { }

  ngOnInit() {
  }

  guardapf() {
    let producto = {}
    let impuestos = []
    producto['descripcion'] = this.descripcion
    producto['codigoprincipal'] = this.codigoprincipal
    producto['codigosecundario'] = this.codigoprincipal
    producto['valorunitario'] = this.preciounitario
    producto['cantidad'] = 1
    if (this.iva) {
      let ivaitem = {
        "codigo": "2",
        "codigoPorcentaje": "2",
        "porcentaje": "1",
        "tarifa": "12",
        "baseImponible": this.preciounitario,
        "valor": math.round((this.preciounitario * 0.12), 2)
      }
      impuestos.push(ivaitem)
    }
    if (this.ice) {
      let iceitem = {
        "codigo": "3",
        "tarifa": this.valorice,
        "porcentaje": "1",
        "codigoPorcentaje": "3072",
        "baseImponible": this.preciounitario,
        "valor": math.round((this.preciounitario * (this.valorice * 0.01)), 2)
      }
      impuestos.push(iceitem)
    }
    producto['impuestos'] = impuestos

    this.srv.postProductoFact(producto).subscribe(data => {
      this.iva = false;
      this.ice = false;
      this.valorice = 5;
      this.descripcion = '';
      this.preciounitario = 0;
      this.codigoprincipal = '';

      this.toastr.success('Producto almacenado exitosamente.', 'Registro Exitoso', {
        closeButton: true,
        timeOut: 5000
      });
    })
  }
}
