import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse } from '@angular/common/http'; // http

// import { AngularFireAuth } from 'angularfire2/auth';
// import * as firebase from 'firebase/app';
import 'rxjs/add/operator/map';

import { ToastrService } from 'ngx-toastr';

import { environment } from '../../environments/environment';

@Injectable()
export class AuthService {
  products;
  data;
  constructor(private http: HttpClient, public toastr: ToastrService) {

  }

  /*
  registerUser(email: string, pass:string){
    return new Promise((resolve, reject)=>{
      this.afAuth.auth.createUserWithEmailAndPassword(email, pass)
      .then(userData=>resolve(userData),
      err=>reject(err));
    });
  }

  loginEmail(email: string, pass:string){
    return new Promise((resolve, reject)=>{
      this.afAuth.auth.signInWithEmailAndPassword(email, pass)
      .then(userData=>resolve(userData),
      err=>reject(err));
    });
  }

  getAut(){
    return this.afAuth.authState.map(auth=>auth);
  }

  logout(){
    return this.afAuth.auth.signOut();
  }

  loginGoogle(){
    return this.afAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  loginFacebook(){
    return this.afAuth.auth.signInWithPopup(new firebase.auth.FacebookAuthProvider());
  }

  loginTwitter(){
    return this.afAuth.auth.signInWithPopup(new firebase.auth.TwitterAuthProvider());
  }
  */

  // TIENDA
  loginEmailValidate(email: string, pass: string) {
    this.http.post(environment.tiendaConfig.BASE_API_URL + 'signup', {
      usuario: email,
      pwd: pass
    }).subscribe(res => {
        console.log(res);
      }, err => {
        console.log('Ocurrio un error.');
      });
  }

  loginPost(email: any, pass: any, proveedor: any) {
    this.getProductCartDB(email);
    if (!proveedor) {
      return this.http.post(environment.tiendaConfig.BASE_API_URL + 'login', {'usuario': email, 'pwd': pass});
    } else {
      return this.http.post(environment.tiendaConfig.BASE_API_URL + 'loginproveedor', { 'usuario': email, 'pwd': pass});
    }
  }

  registerPost(datos) {
    return this.http.post(environment.tiendaConfig.BASE_API_URL + 'signup', datos);
  }

  getUsers() {
    this.http.get(environment.tiendaConfig.BASE_API_URL + 'users').subscribe(res => {
      console.log(res);
    }, err => {
        console.log('Ocurrio un error');
    });
  }



  // Utils cart
  idProducLocaStorage() {
    const idProductLocalStorage = new Array();
    const detailProductArray = JSON.parse(localStorage.getItem('productsCart'));
    for (const position of Object.keys(detailProductArray)) {
      idProductLocalStorage.push({
        '_id': detailProductArray[position].idProduct,
        'cantidad': detailProductArray[position].quantityProductCart,
        'color': detailProductArray[position].color,
        'tallas': detailProductArray[position].talla});
    }
    
    return idProductLocalStorage;
  }

  subtotalFromCart() {
    let subtotalFromCart = 0;
    const detailProductArray = JSON.parse(localStorage.getItem('productsCart'));
    for (const position of Object.keys(detailProductArray)) {
      subtotalFromCart += (Number(detailProductArray[position].price) * Number(detailProductArray[position].quantityProductCart));
    }
    
    return (subtotalFromCart);
  }

  addProductCartDB() {
    const json = {};
    json['productos'] = this.idProducLocaStorage();
    json['subtotal'] = this.subtotalFromCart();
    // console.log(json);

    this.addProductToCartDB(localStorage.getItem('user'), json).subscribe(
      result => {
        this.data = result;
        if (this.data.status === 'OK') {
          //alert('Información almacenada.');
        } else {
          alert('Ocurrió un error al guarda los productos.');
        }

      }
    );

  }

  getProductCartDB(user) {
    this.getProductToCartDB(user).subscribe(data => {
      this.products = data;

        const productosLocal = [];
        for (const prod of this.products.productos) {
          const prodLocal = {};
          prodLocal['idProduct'] = prod['_id']['$oid'];
          prodLocal['nombre'] = prod['nombre'];
          prodLocal['quantityProductCart'] = prod['cantidad'];
          prodLocal['price'] = prod['precio'];
          prodLocal['image'] = prod['image'];
          prodLocal['color'] = prod['color'];
          prodLocal['talla'] = prod['tallas'];
          productosLocal.push(prodLocal);

        }
        localStorage.setItem('productsCart', JSON.stringify(productosLocal));
        // console.log(productosLocal)
      }
    );
  }
  // fin utils cart
  logoutApp() {
    localStorage.removeItem('auth');
    localStorage.removeItem('user');
    localStorage.removeItem('type_user');
  }

  getAllProducts() {
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'products');
  }

  proveedorcedula(cedula) {
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'proveedorcedula/' + cedula );
  }

  getAllCategories() {
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'categories');
  }

  getProductsByCategorie(idCategorie: string) {
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'products/' + idCategorie);
  }

  getProductById(idProduct: string) {
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'product/' + idProduct);
  }

  // DATATABLES
  loadDataProducts() {
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'products');
  }

  loadDataProductsProvider(user: any) {
    return this.http.post(environment.tiendaConfig.BASE_API_URL + 'productsproveedorp', {
      'proveedor': user
    });
  }

  loadDataCategories() {
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'categories');
  }

  // PRODUCTS
  createProductPost(data: any) {
    return this.http.post(environment.tiendaConfig.BASE_API_URL + 'products', data);
  }

  updateProductPut(data: any, id) {
    return this.http.put(environment.tiendaConfig.BASE_API_URL + 'product/' + id, data);
  }

  deleteProduct(id) {
    return this.http.delete(environment.tiendaConfig.BASE_API_URL + 'product/' + id);
  }

  getProductsPagination(page, limit) {
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'productsp/' + page + '/' + limit);
  }

  getProductsByCategoriePagination(idCategorie: string, page, limit) {
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'products/' + idCategorie + '/' + page + '/' + limit);
  }

  getProductsSearch(productName, page, limit) {
    // console.log(environment.tiendaConfig.BASE_API_URL+'productsq/'+productName+'/'+page+'/'+limit);
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'productsq/' + productName + '/' + page + '/' + limit);
  }

  // CATEGORIES
  getCategorieById(idCategorie: string) {
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'categorie/' + idCategorie);
  }

  createCategoriePost(data: any) {
    return this.http.post(environment.tiendaConfig.BASE_API_URL + 'categories', data);
  }

  updateCategoriePut(data: any, id) {
    return this.http.put(environment.tiendaConfig.BASE_API_URL + 'categorie/' + id, data);
  }

  deleteCategorie(id) {
    return this.http.delete(environment.tiendaConfig.BASE_API_URL + 'categorie/' + id);
  }

  uploadImage(data: any) {
    return this.http.post(environment.tiendaConfig.BASE_API_URL + 'subirimagen', data);
  }

  // USERS
  loadDataUsers() {
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'users');
  }

  getUserById(idUser: string) {
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'user/' + idUser);
  }

  createUserPost(data: any) {
    return this.http.post(environment.tiendaConfig.BASE_API_URL + 'users', data);
  }

  updateUserPut(data: any, id) {
    return this.http.put(environment.tiendaConfig.BASE_API_URL + 'user/' + id, data);
  }

  deleteUser(id) {
    return this.http.delete(environment.tiendaConfig.BASE_API_URL + 'user/' + id);
  }

  getDataRuc(identificador) {
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'clienteruc/' + identificador);
  }

  // PROVIDER
  loadDataProviders() {
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'proveedores');
  }

  getProviderById(id: string) {
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'proveedor/' + id);
  }

  createProviderPost(data: any) {
    return this.http.post(environment.tiendaConfig.BASE_API_URL + 'proveedores', data);
  }

  updateProviderPut(data: any, id) {
    return this.http.put(environment.tiendaConfig.BASE_API_URL + 'proveedor/' + id, data);
  }

  deleteProvider(id) {
    return this.http.delete(environment.tiendaConfig.BASE_API_URL + 'proveedor/' + id);
  }

  // ORDER
  addProductToCartDB(user, data: any) {
    return this.http.put(environment.tiendaConfig.BASE_API_URL + 'cart/' + user, data.productos);
  }

  getProductToCartDB(user) {
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'cart/' + user);
  }

  loadAllOrders() {
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'pedidos');
  }

  saveOrder(data: any) {
    return this.http.post(environment.tiendaConfig.BASE_API_URL + 'pedidos', data);
  }

  getOrderProviderById(idOrder: any) {
    // console.log(environment.tiendaConfig.BASE_API_URL+'pedido/'+idOrder);
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'pedido/' + idOrder);
  }

  getProductsProvider(proveedor, page, limit) {
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'productsproveedor/' + proveedor + '/*/' + page + '/' + limit);
  }

  updateStatusOrderPut(data: any, id) {
    return this.http.put(environment.tiendaConfig.BASE_API_URL + 'pedido/' + id, data);
  }

  loadDataOrderProvider(user: any) {
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'pedidos/' + user);
  }

  postProductoFact(param) {
    return this.http.post(environment.tiendaConfig.BASE_API_URL + 'fact_productos/p', param);
  }

  getProductosFact(param) {
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'fact_productos/' + param);
  }

  insertFactura(param) {
    return this.http.post(environment.tiendaConfig.BASE_API_URL + 'facturas', param);
  }

  getId(proveedorruc) {
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'idfactura/' + proveedorruc);
  }

  getSettings() {
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'config');
  }

  putSettings(data) {
    return this.http.put(environment.tiendaConfig.BASE_API_URL + 'config', data);
  }

  postpay(data) {
    return this.http.get(environment.tiendaConfig.BASE_API_URL + 'postpay', data);
  }


}
