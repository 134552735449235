import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../servicios/auth.service';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  products;
  productName = this.route.snapshot.paramMap.get('product');
  limit = 5;
  pag = 1;

  constructor(public authService: AuthService, public AppComponent: AppComponent, private route: ActivatedRoute) {
    this.authService.getProductsSearch(this.productName, this.pag, this.limit).subscribe(
      data => {
        this.products = data[2];
        this.products = this.products.products
      },
      err => console.error(err),
      () => console.log(this.products.products)
    );

    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.scrollup').fadeIn();
      } else {
        $('.scrollup').fadeOut();
      }
    });
  }

  ngOnInit() {
    //this.getProductsSearch();
  }

  scrollTop() {
    $("html, body").animate({ scrollTop: 0 }, 600);
    return false;
  }

  getProductsSearch() {
    console.log('Begin async operation');
    this.pag += 1;
    setTimeout(() => {
      this.authService.getProductsSearch(this.productName, this.pag, this.limit).subscribe(
        data => {
          this.products = this.products.concat(data[2].products);
        },
        error => {
          console.log(error)
        }
      )
      console.log('Async operation has ended');
    }, 600);

  }


}
